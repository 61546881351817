import * as React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import ModelsSection from "../components/sections/ModelsSection";
import ModelItem from "../components/elements/ModelItem";
import Footer from "../components/sections/Footer";

const ModelsSePage = () => (
  <div className="bg-white">
    <PageTitle lang={"se"} pageTitle={"Modeller"} description={""} />
    <Header lang={"se"} />
    <ModelsSection title={"Modeller"} description={""}>
      <ModelItem
        modelTitle={""}
        title={"Modell A – För en kassa"}
        description={
          "Modell A är avsedd för en kassa. Kopplas in via kassans USB port."
        }
        modelPagePath={"/se/models/a"}
        linkText="LÄS MER"
        features={[
          "Ingen extern nätdel, drivs via usb",
          "Kan drivas med seriell koppling för kassaregister 5-36 volt",
          "Kan även drivas med extern strömkälla, om seriell koppling saknar strömmatning",
          "Kontrollenheter med lagring från 128 000 000+ kvitton med minnen från 32 Gb upp till 128 Gb kapacitet",
        ]}
        imageName={"posplus_yellow.jpg"}
      />
      <ModelItem
        modelTitle={""}
        title={"Modell C - För servrar och flera kassor"}
        description={
          "Modell C är avsedd för att kopplas till en central server, lokalt eller via internet och på så sätt fungera som kontrollenhet för en mängd kassor."
        }
        modelPagePath={"/se/models/c"}
        linkText="LÄS MER"
        features={[
          "Upp till 9000 användare",
          "Ingen extern nätdel, drivs via usb",
          "Minne 128GB för upp till 500 miljoner kvitton",
          "Liten storlek, 44x27x14mm",
        ]}
        imageName={"posplus_green.jpg"}
      />
      <ModelItem
        modelTitle={""}
        title={"Buffert enhet - för att eliminera driftsstörningar och ger stöd för flera kassaregister"}
        description={
          "Skattedata skickas till servern i realtid. All skatteinformation replikeras och förvaras på båda sidorna: Buffertenhet och Kontrollserver."
        }
        modelPagePath={"/se/models/buffering-unit"}
        linkText="LÄS MER"
        features={[
          "SD-kort som lagrar skatteuppgifter i minst 5 år",
          "Kan vara offline i 48 timmar",
          "Skatteverket kommer inte att besöka din POS för att ladda ner skattedata",
          "Kan anslutas till lokalt Wi-Fi",
        ]}
      />
    </ModelsSection>
    <Footer lang={"se"} />
  </div>
);

export default ModelsSePage;
